import PageMessage from '../components/PageMessage'
import { graphql } from 'gatsby'

export const query = graphql`
  query PageMessage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default PageMessage
