// extracted by mini-css-extract-plugin
export var betaBadge = "style-module--betaBadge--666b7";
export var body = "style-module--body--5e372";
export var companyLinks = "style-module--companyLinks--4c30b";
export var footerCompanyDetails = "style-module--footerCompanyDetails--f5b95";
export var footerContainer = "style-module--footerContainer--b4e94";
export var footerContent = "style-module--footerContent--0fc3f";
export var footerLinks = "style-module--footerLinks--e630c";
export var linkContainer = "style-module--linkContainer--35b79";
export var messageFooterContainer = "style-module--messageFooterContainer--9a1a3";
export var sitterLink = "style-module--sitterLink--38a0c";
export var templateFullWidth = "style-module--templateFullWidth--33b15";
export var text = "style-module--text--552f7";