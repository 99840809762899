import {
  ChannelInner,
  MessagingSidebar,
  MessagingThreadHeader,
  SendButton,
} from './components'
import Messages from './components/Messages'
import { GiphyContextProvider, useThemeContext } from './context'
import {
  useConnectUser,
  useChecklist,
  useUpdateAppHeightOnResize,
} from './hooks'
import './styles/index.css'
import type { StreamChatGenerics } from './types'
import data from '@emoji-mart/data'
import { init, SearchIndex } from 'emoji-mart'
import React, { useState } from 'react'
import type { ChannelFilters, ChannelOptions, ChannelSort } from 'stream-chat'
import { Channel, Chat as BaseChat, useChatContext } from 'stream-chat-react'
import 'stream-chat-react/dist/css/v2/index.css'
import { EmojiPicker } from 'stream-chat-react/emojis'

init({ data })

type ChatProps = {
  apiKey: string
  userToConnect: { id: string; name?: string; image?: string }
  userToken: string | undefined
  channel: string
  targetOrigin: string
  channelListOptions: {
    options: ChannelOptions
    filters: ChannelFilters
    sort: ChannelSort
  }
}

const WrappedEmojiPicker = () => {
  const { theme } = useThemeContext()

  return <EmojiPicker pickerProps={{ theme }} />
}

type ActiveChannelProps = {
  channel: string
}

const ActiveChannel = (props: ActiveChannelProps) => {
  const { client, setActiveChannel } = useChatContext<StreamChatGenerics>()

  React.useEffect(() => {
    const conversation = client.channel('messaging', {
      members: [props.channel, client.userID as string],
    })

    setActiveChannel(conversation)
    conversation.watch() // TODO: Watch all conversations, not just active

    return () => {
      setActiveChannel(undefined)
      conversation.stopWatching()
    }
  }, [props.channel])

  return null
}

const Chat = (props: ChatProps) => {
  const { apiKey, userToConnect, userToken, targetOrigin, channelListOptions } =
    props
  const [isCreating, setIsCreating] = useState(false)

  const chatClient = useConnectUser<StreamChatGenerics>(
    apiKey,
    userToConnect,
    userToken
  )
  const { themeClassName } = useThemeContext()

  useChecklist(chatClient, targetOrigin)
  useUpdateAppHeightOnResize()

  React.useEffect(() => {
    console.log('mounted 123')

    return () => {
      console.log('unmounted 123')
    }
  }, [])

  if (!chatClient) {
    return null // render nothing until connection to the backend is established
  }

  return (
    <>
      <BaseChat client={chatClient} theme={`messaging ${themeClassName}`}>
        <MessagingSidebar
          channelListOptions={channelListOptions}
          onCreateChannel={() => setIsCreating(!isCreating)}
          onPreviewSelect={() => setIsCreating(false)}
        />
        {props.channel ? (
          <>
            <ActiveChannel channel={props.channel} />
            <Channel
              maxNumberOfFiles={10}
              multipleUploads={true}
              SendButton={SendButton}
              // ThreadHeader={MessagingThreadHeader}
              TypingIndicator={() => null}
              EmojiPicker={WrappedEmojiPicker}
              emojiSearchIndex={SearchIndex}
              // @ts-ignore
              TriggerProvider={(props) => props.children}
            >
              {/* <GiphyContextProvider> */}
              <ChannelInner />
              {/* </GiphyContextProvider> */}
            </Channel>
          </>
        ) : (
          <Messages {...props} />
        )}
      </BaseChat>
    </>
  )
}

export default Chat
