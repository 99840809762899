import { CustomReservationMessage } from '../CustomReservationMessage'
import './CustomMessage.scss'
import React, { useRef } from 'react'
import {
  Attachment,
  Avatar,
  messageHasReactions,
  MessageOptions,
  MessageRepliesCountButton,
  MessageStatus,
  MessageText,
  MessageTimestamp,
  ReactionSelector,
  SimpleReactionsList,
  useMessageContext,
} from 'stream-chat-react'

export default () => {
  const {
    isReactionEnabled,
    message,
    reactionSelectorRef,
    showDetailedReactions,
  } = useMessageContext()

  const canReact = isReactionEnabled
  const hasReactions = messageHasReactions(message)
  const hasAttachments = message.attachments && message.attachments.length > 0

  return (
    <>
      <div>
        <div className='message-custom-wrapper'>
          {message.payType && <CustomReservationMessage {...message.payType} />}
        </div>
        <div className='message-wrapper'>
          <div className='message-wrapper-content'>
            {/* <MessageOptions messageWrapperRef={messageWrapperRef} /> */}
            {showDetailedReactions && canReact && (
              <ReactionSelector ref={reactionSelectorRef} />
            )}
            <MessageText />
            {/* <MessageStatus /> */}
            {/* <MessageRepliesCountButton reply_count={message.reply_count} /> */}
          </div>
        </div>
        <div className='message-header'>
          <Avatar image={message.user?.image} />
          <div className='message-header-name'>
            {message.user?.name} LV Dog Hotel
          </div>
          <div className='message-header-timestamp'>
            <MessageTimestamp />
          </div>
        </div>
      </div>
      <div>
        {hasAttachments && <Attachment attachments={message.attachments} />}
        {hasReactions && <SimpleReactionsList />}
      </div>
    </>
  )
}
