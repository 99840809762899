import { CustomMessage } from '../../components'
// import { useGiphyContext } from '../../context'
import type { StreamChatGenerics } from '../../types'
import { CustomReservationMessage } from '../CustomReservationMessage'
import * as style from './style.module.scss'
import React from 'react'
import { logChatPromiseExecution } from 'stream-chat'
import {
  MessageList,
  MessageInput,
  MessageToSend,
  Window,
  useChannelActionContext,
  Thread,
  useMessageInputContext,
} from 'stream-chat-react'

export type ChannelInnerProps = {
  // title: string
  theme: string
}

const ChannelInner = (props: ChannelInnerProps) => {
  // const { giphyState, setGiphyState } = useGiphyContext()

  const { sendMessage } = useChannelActionContext<StreamChatGenerics>()
  const searchParams = new URLSearchParams(location.search)
  const payType = searchParams.has('m')
    ? JSON.parse(atob(searchParams.get('m') as string))
    : undefined
  const overrideSubmitHandler = (
    message: MessageToSend<StreamChatGenerics>
  ) => {
    let updatedMessage

    if (message.attachments?.length && message.text?.startsWith('/giphy')) {
      const updatedText = message.text.replace('/giphy', '')
      updatedMessage = { ...message, text: updatedText }
    }

    // if (giphyState) {
    //   const updatedText = `/giphy ${message.text}`
    //   updatedMessage = { ...message, text: updatedText }
    // }

    if (sendMessage) {
      const newMessage = updatedMessage || message
      const parentMessage = newMessage.parent

      const messageToSend = {
        ...newMessage,
        parent: parentMessage
          ? {
              ...parentMessage,
              created_at: parentMessage.created_at?.toString(),
              pinned_at: parentMessage.pinned_at?.toString(),
              updated_at: parentMessage.updated_at?.toString(),
            }
          : undefined,
      }

      const customMessageData = payType ? { payType } : undefined
      const sendMessagePromise = sendMessage(
        messageToSend,
        // @ts-ignore Custom message contains custom payType property
        customMessageData
      )

      logChatPromiseExecution(sendMessagePromise, 'send message')
    }

    // setGiphyState(false)
  }

  const actions = ['delete', 'edit', 'flag', 'mute', 'react', 'reply']

  return (
    <>
      <Window>
        <div className={style.title}>{props.title}</div>
        <MessageList messageActions={actions} Message={CustomMessage} />
        <div className={style.messageInput}>
          {payType && <CustomReservationMessage {...payType} />}
          <MessageInput
            additionalTextareaProps={{
              minRows: 3,
              maxRows: 3,
              placeholder:
                'Add a message to this request and click "Send" to start a conversation.',
            }}
            focus
            grow
            overrideSubmitHandler={overrideSubmitHandler}
          />
        </div>
      </Window>
      <Thread />
    </>
  )
}

export default ChannelInner
